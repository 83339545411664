@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Robot Rebels";
  src: url("./assets/RobotRebels.ttf") format("truetype");
}

@font-face {
  font-family: "Robot Rebels Italic";
  src: url("./assets/RobotRebelsItalic.ttf") format("truetype");
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans 3", sans-serif;
  font-family: "Roboto", sans-serif;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.root-container {
  min-width: 600px; /* Set the minimum width to 320px */
  width: 100%;
  height: 100vh; /* Full height for demonstration */
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center;
  background-color: #f0f0f0; */
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* border-radius: 10px; */
}

::-webkit-scrollbar-track:hover {
  background: #555;
  /* border-radius: 10px; */
}

/* For WebKit browsers */
.MuiDataGrid-root::-webkit-scrollbar {
  width: 8px;
}

.MuiDataGrid-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.MuiDataGrid-root::-webkit-scrollbar-thumb {
  background: #888;
}

.MuiDataGrid-root::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gradient-text-green {
  background: linear-gradient(90deg, #ffff3f 0%, #007f5f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-pink {
  background: linear-gradient(90deg, #f8a5cc 0%, #cf0050 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .app-topbar {
  background: linear-gradient(90deg, #1a1a19 0%, #474747 100%);
} */
