.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hoverImage {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 400px;
  z-index: 10;
  border: 1px solid #ccc;
  background-color: #fff;
}

.MuiDataGrid-cell:hover .hoverImage {
  display: block;
}

.root-container {
  min-width: 600px; /* Set the minimum width to 320px */
  width: 100%;
  height: 100vh; /* Full height for demonstration */
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center;
  background-color: #f0f0f0; */
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.card {
  user-select: none;
  padding: 16px;
  margin: 8px 0;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.dragging {
  transform: rotate(10deg);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
